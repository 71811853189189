import { useContext, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, IconButton, styled } from '@mui/material'

import { GameStateContext } from '../../context/Game'
import { SocketContext } from '../../context/Socket'
import { type CardProps } from '../../models'
import { CardFocusDialog } from '../CardFocusDialog'
import CardsList from '../GameBoard/CardsList'

interface CardSelectionDialogProps {
  open: boolean
  source: string
  cards: string[]
  noActions?: boolean
  onClose: () => void
}

const DialogNoBackground = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: '#fff'
  }
}))

export function CardSelectionDialog({ open, source, cards, noActions = false, onClose }: CardSelectionDialogProps): JSX.Element {
  const socket = useContext(SocketContext)
  const { handler } = useContext(GameStateContext)

  const { id } = handler.getPlayerInfo()

  const [currentFocusSelection, setCurrentFocusSelection] = useState<CardProps | undefined>(undefined)

  const actions = [
    {
      text: 'Take To Hand',
      action: (card: string) => {
        socket.emit('move-pile', { card, source, target: `player_${id}_hand` })
        onClose()
      }
    },
    {
      text: 'Take To Stable',
      excludeType: ['downgrade', 'instant', 'magic'],
      action: (card: string) => {
        socket.emit('move-pile', { card, source, target: `player_${id}_stable` })
        onClose()
      }
    },
    {
      text: 'Focus',
      action: (card: string) => {
        const cardDetails = handler.getCardDetails(card)
        setCurrentFocusSelection(cardDetails)
      }
    }
  ]

  return (
    <DialogNoBackground
      onClose={() => {
        onClose()
      }}
      open={open}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ overflow: 'auto' }}>
        <CardsList cards={cards} actions={noActions ? [] : actions} />
      </Box>
      {currentFocusSelection !== undefined && (
        <CardFocusDialog
          open={currentFocusSelection !== undefined}
          onClose={() => {
            setCurrentFocusSelection(undefined)
          }}
          card={currentFocusSelection}
        />
      )}
    </DialogNoBackground>
  )
}
