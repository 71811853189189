import { createContext } from 'react'
import { io } from 'socket.io-client'

const session = { sessionID: localStorage.getItem('sessionID') }

export const socket = io('/', { auth: session })

export function getSessionID(): string | null {
  return session.sessionID
}

export function setSessionID(sessionID: string): void {
  session.sessionID = sessionID
  localStorage.setItem('sessionID', sessionID)
}

export const SocketContext = createContext(socket)
