import { forwardRef, useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'
import { AppBar, Box, CardContent, DialogContent, Grid, IconButton, ImageListItemBar, Skeleton, Slide, Toolbar, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { type TransitionProps } from '@mui/material/transitions'

import { type CardProps } from '../../models'
import { CardDetailsDialog } from '../CardDetailsDialog'
import { CollapsibleCard } from '../CollapsibleCard'

interface DeckInfoDialogProps {
  deckCards: CardProps[]
  open: boolean
  onCancel: () => void
}

interface DeckListCardProps {
  card: CardProps
  onActionClick: () => void
}

type CardsByType = Record<string, CardProps[]>

const types: Record<string, string> = {
  baby_unicorn: 'Baby Unicorn',
  basic_unicorn: 'Basic Unicorn',
  magical_unicorn: 'Magical Unicorn',
  magic: 'Magic',
  instant: 'Instant',
  upgrade: 'Upgrade',
  downgrade: 'Downgrade'
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

function DeckListCard({ card, onActionClick }: DeckListCardProps): JSX.Element {
  const [loaded, setLoaded] = useState(false)
  const ref: any = useRef()

  const onLoad = (): void => {
    setLoaded(true)
  }

  useEffect(() => {
    if (ref.current?.complete === true) {
      onLoad()
    }
  }, [])

  return (
    <Grid item xs={1} sx={{ position: 'relative' }}>
      {!loaded && <Skeleton variant="rectangular" sx={{ width: '100%', height: 'auto', aspectRatio: '15 / 20.95' }} />}
      <img src={`/api/${card.image}`} alt={card.title} onLoad={onLoad} style={{ display: !loaded ? 'none' : 'block', width: '100%' }} />
      {loaded && (
        <ImageListItemBar
          title={card.title}
          actionIcon={
            <IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }} onClick={onActionClick}>
              <InfoIcon />
            </IconButton>
          }
          sx={{ ml: 1 }}
        />
      )}
    </Grid>
  )
}

export function DeckInfoDialog({ deckCards, open, onCancel }: DeckInfoDialogProps): JSX.Element {
  const [cardSelected, setCardSelected] = useState<CardProps>()

  const cards: CardsByType = {}

  for (const card of deckCards) {
    const { type } = card

    const typeCards = cards[type] !== undefined ? cards[type] : (cards[type] = [])

    typeCards.push(card)
  }

  const handleClose = (): void => {
    onCancel()
  }

  const generateCardsListElements = (): JSX.Element[] => {
    const elements: JSX.Element[] = []

    for (const type in cards) {
      const items = cards[type].map((card) => (
        <DeckListCard
          key={card.id}
          card={card}
          onActionClick={() => {
            setCardSelected(card)
          }}
        />
      ))

      const title = (
        <Typography variant="h6" component="h6">
          {types[type]}
        </Typography>
      )

      elements.push(
        <Box sx={{ mb: 2 }} key={type}>
          <CollapsibleCard title={title}>
            <CardContent sx={{ paddingTop: 0 }}>
              <Grid container columns={{ xs: 3, sm: 5, md: 6, lg: 7, xl: 8 }} spacing={1}>
                {items}
              </Grid>
            </CardContent>
          </CollapsibleCard>
        </Box>
      )
    }

    return elements
  }

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullScreen={true} TransitionComponent={Transition}>
        <AppBar color="transparent" sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Deck Details
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>{generateCardsListElements()}</DialogContent>
      </Dialog>
      <CardDetailsDialog
        open={cardSelected !== undefined}
        card={cardSelected}
        onCancel={() => {
          setCardSelected(undefined)
        }}
      />
    </>
  )
}
