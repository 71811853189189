import { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, type SxProps, type Theme } from '@mui/material'
import ButtonBase from '@mui/material/ButtonBase/ButtonBase'
import Card from '@mui/material/Card'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'

interface CollapsibleCardProps {
  title: JSX.Element
  titlePadding?: number
  sx?: SxProps<Theme>
  children: JSX.Element
}

export function CollapsibleCard({ title, titlePadding, sx, children }: CollapsibleCardProps): JSX.Element {
  const [open, setOpen] = useState(true)

  const generateHeader = (): JSX.Element => {
    if (titlePadding === 0) {
      return (
        <ButtonBase
          onClick={() => {
            setOpen(!open)
          }}
          sx={{ width: '100%' }}
        >
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: typeof titlePadding === 'number' ? titlePadding : 2 }}>
            {title}
          </Box>
        </ButtonBase>
      )
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: typeof titlePadding === 'number' ? titlePadding : 2 }}>
        {title}
        <IconButton
          onClick={() => {
            setOpen(!open)
          }}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Box>
    )
  }

  return (
    <>
      <Card variant="outlined" sx={sx}>
        {generateHeader()}
        <Collapse in={open} timeout="auto">
          {children}
        </Collapse>
      </Card>
    </>
  )
}
