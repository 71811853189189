import { useContext, useEffect, useState } from 'react'
import { Box, Container, Divider, useMediaQuery, useTheme } from '@mui/material'

import { CardSelectionDialog } from '../components/CardSelectionDialog'
import { DecksPiles, Header, Player, PlayersList } from '../components/GameBoard'
import { GameHandler, type GameHandlerProps, GameStateContext } from '../context/Game'
import { SocketContext } from '../context/Socket'
import { type DeckProps, type GameStateProps, type ServerGameStateProps } from '../models'

interface GameStateContextInterface {
  state: GameStateProps
  handler: GameHandlerProps
}

function Board(): JSX.Element {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const shortHeight = useMediaQuery('(max-height: 800px)')

  const socket = useContext(SocketContext)

  const [{ state, handler }, setGameState] = useState<GameStateContextInterface>({
    state: GameHandler.getState(),
    handler: GameHandler
  })

  const [loading, setLoading] = useState<boolean>(true)
  const [playerHand, setPlayerHand] = useState<string[]>()

  // TODO Make it fetch more dynamically
  const fetchDeckData = async (): Promise<DeckProps | undefined> => {
    const decks: DeckProps[] = await fetch('/api/decks.json').then(async (res) => await res.json())

    return decks[0] !== undefined ? decks[0] : undefined
  }

  const prepareBoard = async (): Promise<void> => {
    const deckData = await fetchDeckData()

    if (deckData === undefined) {
      setLoading(false)
      return
    }

    setTimeout(() => {
      handler.setDeck(deckData)

      addListeners()

      setLoading(false)
    }, 1500)
  }

  const addListeners = (): void => {
    socket.on('game-state', (state: ServerGameStateProps) => {
      console.log('Game State')
      handler.setState(state)

      setGameState({ state: handler.getState(), handler })
    })

    socket.on('show-hand', (sourcePlayer, targetPlayer) => {
      if (targetPlayer !== handler.getPlayerInfo().id) return

      const [playerData] = handler.getOtherPlayersInfo().filter((el) => sourcePlayer === el.id)
      setPlayerHand(playerData.hand)
    })

    socket.on('stop-show-hand', (sourcePlayer, targetPlayer) => {
      setPlayerHand(undefined)
    })

    socket.emit('setup')
  }

  const cleanBoard = (): void => {
    setLoading(true)
  }

  useEffect(() => {
    prepareBoard().catch(() => {
      console.log('Failed to prepare board')
    })

    return () => {
      cleanBoard()
    }
  }, [])

  return (
    <GameStateContext.Provider value={{ state, handler }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', height: '100dvh' }}>
        <Header loading={loading} />
        {!loading && (
          <Container
            component="main"
            maxWidth={false}
            sx={{ py: 2, display: 'flex', flexDirection: mobile ? 'column' : 'row', gap: 4, overflow: mobile ? 'auto' : 'hidden', flexGrow: 1 }}
          >
            <Box component="div" width={mobile ? '100%' : '50%'} sx={{ display: 'flex', flexDirection: 'column', overflow: mobile ? null : 'auto' }}>
              <Box component="div" sx={{ mb: 2 }}>
                <DecksPiles />
              </Box>
              <Divider sx={{ mb: 2 }}></Divider>
              <Box component="div" sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: shortHeight ? null : 'hidden' }}>
                <Player />
              </Box>
            </Box>
            <Box component="div" width={mobile ? '100%' : '50%'} sx={{ overflow: mobile ? null : 'auto' }}>
              <PlayersList />
            </Box>
          </Container>
        )}
      </Box>
      {playerHand !== undefined && (
        <CardSelectionDialog
          open={playerHand !== undefined}
          onClose={() => {
            socket.emit('stop-show-hand')
          }}
          source={'player'}
          cards={playerHand}
          noActions
        />
      )}
    </GameStateContext.Provider>
  )
}

export default Board
