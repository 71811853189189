import { useContext, useState } from 'react'
import { Box, Typography } from '@mui/material'

import { GameStateContext } from '../../context/Game'
import { SocketContext } from '../../context/Socket'
import { CollapsibleCard } from '../CollapsibleCard'

import CardsList from './CardsList'
import { CardProps } from '../../models'
import { CardFocusDialog } from '../CardFocusDialog'

export function PlayersList(): JSX.Element {
  const socket = useContext(SocketContext)
  const { handler } = useContext(GameStateContext)
  
  const [currentFocusSelection, setCurrentFocusSelection] = useState<CardProps | undefined>(undefined)

  const { id } = handler.getPlayerInfo()
  const players = handler.getOtherPlayersInfo()

  return (
    <>
      {players.map((player, i) => {
        const title = (
          <Box sx={{ display: 'flex', alignItems: 'center', px: 1, gap: 1 }}>
            <Typography variant="h6" component="div">
              {player.name}
            </Typography>
            <Typography>|</Typography>
            <Typography variant="subtitle2" component="div">
              Hand: {player.hand.length} cards
            </Typography>
          </Box>
        )

        const actions = [
          { text: 'Destroy', action: (card: string) => socket.emit('move-pile', { card, source: `player_${player.id}_stable`, target: 'discard' }) },
          { text: 'Steal To Stable', action: (card: string) => socket.emit('move-pile', { card, source: `player_${player.id}_stable`, target: `player_${id}_stable` }) },
          {
            text: 'Focus',
            action: (card: string) => {
              const cardDetails = handler.getCardDetails(card)
              setCurrentFocusSelection(cardDetails)
            }
          }
        ]

        return (
          <CollapsibleCard key={player.id} title={title} titlePadding={0} sx={{ mb: players.length - 1 === i ? 0 : 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1, pt: 0 }}>
              <Box component="div">
                <CardsList cards={player.stable.unicorns} showBackCard actions={actions} />
              </Box>
              {player.stable.actions.length !== 0 && (
                <Box component="div">
                  <CardsList cards={player.stable.actions} actions={actions} />
                </Box>
              )}
            </Box>
          </CollapsibleCard>
        )
      })}
      {currentFocusSelection !== undefined && (
        <CardFocusDialog
          open={currentFocusSelection !== undefined}
          onClose={() => {
            setCurrentFocusSelection(undefined)
          }}
          card={currentFocusSelection}
        />
      )}
    </>
  )
}
