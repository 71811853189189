import { createContext } from 'react'

import { type CardProps, type DeckProps, type GameStateProps, type PilesProps, type PlayerProps, type ServerGameStateProps, type SimplePlayerProps } from '../models'

import { getSessionID } from './Socket'

export interface GameHandlerProps {
  deck: DeckProps
  deckCards: CardProps[]
  cardsDetails: Record<string, CardProps>
  getState: () => GameStateProps
  setDeck: (deck: DeckProps) => void
  setState: (game: ServerGameStateProps) => void
  resetState: () => void
  getDeckInfo: () => DeckProps
  getDeckCards: () => CardProps[]
  getCurrentPlayer: () => string
  getPlayersOrder: () => SimplePlayerProps[]
  getPlayerInfo: () => PlayerProps
  getOtherPlayersInfo: () => PlayerProps[]
  getPilesInfo: () => PilesProps
  getNurseryPile: () => string[]
  getDiscardPile: () => string[]
  getDeckPile: () => string[]
  getCardDetails: (card: string) => CardProps | undefined
}

const gameState: GameStateProps = {
  player: { id: '', name: '', hand: [], stable: { unicorns: [], actions: [] } },
  otherPlayers: [],
  piles: { nursery: [], discard: [], deck: [] },
  currentPlayer: '',
  playersOrder: []
}

export const GameHandler: GameHandlerProps = {
  deck: {
    id: '',
    title: '',
    expansion: '',
    cards: {
      base: undefined,
      expansion: undefined
    }
  },
  deckCards: [],
  cardsDetails: {},
  getState: function (): GameStateProps {
    return gameState
  },
  setDeck: function (deck: DeckProps): void {
    const { base, expansion } = deck.cards

    const cards: Record<string, CardProps> = {}

    if (base != null) {
      base.forEach((card) => {
        cards[card.id] = card
      })

      this.deckCards = base
    }

    this.deck = deck
    this.cardsDetails = cards
  },
  setState: function ({ players, nursery, deck, discard, turn }: ServerGameStateProps): void {
    const [playerData] = players.filter((player) => player.id === getSessionID())
    const otherPlayersData = players.filter((player) => player.id !== getSessionID())

    gameState.player = playerData
    gameState.otherPlayers = otherPlayersData
    gameState.piles = { nursery, discard, deck }
    gameState.currentPlayer = turn
    gameState.playersOrder = players.map((player) => ({ id: player.id, name: player.name }))
  },
  resetState: function (): void {
    gameState.player = { id: '', name: '', hand: [], stable: { unicorns: [], actions: [] } }
    gameState.otherPlayers = []
    gameState.piles = { nursery: [], discard: [], deck: [] }
  },
  getDeckInfo: function (): DeckProps {
    return this.deck
  },
  getDeckCards: function (): CardProps[] {
    return this.deckCards
  },
  getCurrentPlayer: function (): string {
    return gameState.currentPlayer
  },
  getPlayersOrder: function (): SimplePlayerProps[] {
    return gameState.playersOrder
  },
  getPlayerInfo: function (): PlayerProps {
    return gameState.player
  },
  getOtherPlayersInfo: function (): PlayerProps[] {
    return gameState.otherPlayers
  },
  getPilesInfo: function (): PilesProps {
    return gameState.piles
  },
  getNurseryPile: function (): string[] {
    return gameState.piles.nursery
  },
  getDiscardPile: function (): string[] {
    return gameState.piles.discard
  },
  getDeckPile: function (): string[] {
    return gameState.piles.deck
  },
  getCardDetails: function (card: string): CardProps | undefined {
    if (typeof card !== 'string') return

    const match = card.match(/_\d+$/g)
    const suffix = match !== null ? match[0] : undefined

    if (suffix === undefined) return

    const key = card.replace(suffix, '')
    return this.cardsDetails[key]
  }
}

export const GameStateContext = createContext({ state: gameState, handler: GameHandler })

export const RulesCard: CardProps = {
  id: 'rules',
  title: 'Rules',
  description: '',
  image: 'images/rules.png',
  quantity: 0,
  type: ''
}
