import { useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AppBar, Button, Container, LinearProgress, Toolbar, Typography, useTheme } from '@mui/material'

import { SocketContext } from '../../context/Socket'

import { GameStateContext, RulesCard } from '../../context/Game'
import { type CardProps } from '../../models'
import { CardFocusDialog } from '../CardFocusDialog'
import { DeckInfoDialog } from '../DeckInfoDialog'

interface HeaderProps {
  loading: boolean
}

export function Header({ loading }: HeaderProps): JSX.Element {
  const theme = useTheme()

  const [searchParams] = useSearchParams()
  const isAdmin = searchParams.get('type') === 'eusoufoda'

  const socket = useContext(SocketContext)

  const { handler } = useContext(GameStateContext)

  const [deckInfoDialogOpen, setDeckInfoDialogOpen] = useState<boolean>(false)
  const [currentFocusSelection, setCurrentFocusSelection] = useState<CardProps | undefined>(undefined)

  return (
    <>
      <AppBar position="relative" color="transparent" elevation={1}>
        <Container component="div" maxWidth="lg" disableGutters>
          <Toolbar variant="dense">
            <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
              Unstable Unicorns
            </Typography>
            {!loading && (
              <Button
                variant="contained"
                onClick={() => {
                  setDeckInfoDialogOpen(true)
                }}
                size="small"
                color="secondary"
              >
                Check Deck Cards
              </Button>
            )}
            {!loading && (
              <Button
                variant="contained"
                onClick={() => {
                  setCurrentFocusSelection(RulesCard)
                }}
                size="small"
                color="secondary"
                sx={{ ml: 1 }}
              >
                Rules
              </Button>
            )}
            {!loading && isAdmin && (
              <Button
                variant="contained"
                onClick={() => {
                  socket.emit('reset-game')
                }}
                size="small"
                color="secondary"
                sx={{ ml: 1 }}
              >
                Reset
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {loading && <LinearProgress />}
      <DeckInfoDialog
        open={deckInfoDialogOpen}
        onCancel={() => {
          setDeckInfoDialogOpen(false)
        }}
        deckCards={handler.getDeckCards()}
      />
      {currentFocusSelection != null && (
        <CardFocusDialog
          open={currentFocusSelection !== undefined}
          onClose={() => {
            setCurrentFocusSelection(undefined)
          }}
          card={currentFocusSelection}
        />
      )}
    </>
  )
}
