import { Box, Dialog, styled, Typography } from '@mui/material'

import { type CardProps } from '../../models'

interface CardFocusDialogProps {
  open: boolean
  card: CardProps
  onClose: () => void
}

const DialogNoBackground = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  }
}))

export function CardFocusDialog(props: CardFocusDialogProps): JSX.Element {
  const { open, card, onClose } = props

  return (
    <DialogNoBackground
      onClose={() => {
        onClose()
      }}
      open={open}
      sx={{ backgroundColor: 'transparent' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', color: '#fff', overflow: 'hidden' }}>
        <Typography variant="h5" component="h5">
          {card.title}
        </Typography>
        <Typography component="div" variant="body1">
          {card.description}
        </Typography>
        <img src={`/api/${card.image}`} alt={card.title} loading="lazy" style={{ maxWidth: '100%', maxHeight: 600, overflow: 'hidden' }} />
      </Box>
    </DialogNoBackground>
  )
}
