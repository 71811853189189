import { Box } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

export function Navbar() {
  return (
    <AppBar position="relative" color="transparent" elevation={1}>
      <Container component="div" maxWidth="md" disableGutters>
        <Toolbar>
          <Box component="img" sx={{ height: 56, mr: 2 }} alt="Logo" src="logo.png" />
          <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
            Unstable Unicorns
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
