import { useEffect } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material'

import { socket, SocketContext } from './context/Socket'
import Board from './pages/Board'
import SimpleHome from './pages/SimpleHome'

import './App.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

const router = createBrowserRouter([
  {
    path: '/',
    // element: <Home />,
    element: <SimpleHome />
  },
  {
    path: '/board',
    element: <Board />
  }
])

function App(): JSX.Element {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = createTheme({
    palette: {
      //mode: prefersDarkMode ? 'dark' : 'light'
      mode: 'light'
    }
  })

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected')
    })

    socket.on('disconnect', () => {
      console.log('Disconnected')
    })

    return () => {
      socket.off('connect')
      socket.off('disconnect')
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SocketContext.Provider value={socket}>
        <RouterProvider router={router} />
      </SocketContext.Provider>
    </ThemeProvider>
  )
}

export default App
