import { useContext, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, Button, Collapse, Divider, IconButton, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material'

import { GameStateContext } from '../../context/Game'
import { SocketContext } from '../../context/Socket'
import { CardProps, type PlayerProps } from '../../models'

import CardsList from './CardsList'
import { CardFocusDialog } from '../CardFocusDialog'

function ShowHandButton({ players }: { players: PlayerProps[] }): JSX.Element {
  const socket = useContext(SocketContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: any): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const showHand = (player: string): void => {
    socket.emit('show-hand', player)
    handleClose()
  }

  return (
    <>
      <Button variant="contained" size="small" onClick={handleClick} color="secondary">
        Show Hand
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {players.map((player) => (
          <MenuItem
            key={player.id}
            onClick={() => {
              showHand(player.id)
            }}
          >
            {player.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

function TradeHandButton({ players }: { players: PlayerProps[] }): JSX.Element {
  const socket = useContext(SocketContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: any): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const tradeHand = (player: string): void => {
    socket.emit('trade-hand', player)
    handleClose()
  }

  return (
    <>
      <Button variant="contained" size="small" onClick={handleClick} color="secondary">
        Trade Hand
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {players.map((player) => (
          <MenuItem
            key={player.id}
            onClick={() => {
              tradeHand(player.id)
            }}
          >
            {player.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export function Player(): JSX.Element {
  const shortHeight = useMediaQuery('(max-height: 800px)')

  const socket = useContext(SocketContext)
  const { handler } = useContext(GameStateContext)
  const [currentFocusSelection, setCurrentFocusSelection] = useState<CardProps | undefined>(undefined)

  const { id, hand, stable } = handler.getPlayerInfo()
  const otherPlayers = handler.getOtherPlayersInfo()

  const [open, setOpen] = useState(true)


  const sendCardActions = otherPlayers.map((player) => ({
    text: `Send To ${player.name}`,
    action: (card: string) => socket.emit('move-pile', { card, source: `player_${id}_stable`, target: `player_${player.id}_stable` })
  }))

  const stableActions = [
    { text: 'Sacrifice', action: (card: string) => socket.emit('move-pile', { card, source: `player_${id}_stable`, target: 'discard' }) },
    { text: 'Return To Hand', action: (card: string) => socket.emit('move-pile', { card, source: `player_${id}_stable`, target: `player_${id}_hand` }) },
    ...sendCardActions,
    {
      text: 'Focus',
      action: (card: string) => {
        const cardDetails = handler.getCardDetails(card)
        setCurrentFocusSelection(cardDetails)
      }
    }
  ]

  const sendDowngradeActions = otherPlayers.map((player) => ({
    text: `Send To ${player.name}`,
    includeType: ['downgrade'],
    action: (card: string) => socket.emit('move-pile', { card, source: `player_${id}_hand`, target: `player_${player.id}_stable` })
  }))

  const handActions = [
    {
      text: 'Play',
      excludeType: ['downgrade', 'instant', 'magic'],
      action: (card: string) => socket.emit('move-pile', { card, source: `player_${id}_hand`, target: `player_${id}_stable` })
    },
    { text: 'Play', includeType: ['instant', 'magic'], action: (card: string) => socket.emit('move-pile', { card, source: `player_${id}_hand`, target: 'discard' }) },
    ...sendDowngradeActions,
    { text: 'Discard', action: (card: string) => socket.emit('move-pile', { card, source: `player_${id}_hand`, target: 'discard' }) },
    {
      text: 'Focus',
      action: (card: string) => {
        const cardDetails = handler.getCardDetails(card)
        setCurrentFocusSelection(cardDetails)
      }
    }
  ]

  return (
    <>
      <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
        {hand.length > 0 && <TradeHandButton players={otherPlayers} />}
        {hand.length > 0 && <ShowHandButton players={otherPlayers} />}
        {id === handler.getCurrentPlayer() && (
          <Button variant="contained" size="small" onClick={() => socket.emit('end-turn')} color="secondary">
            End Turn
          </Button>
        )}
      </Box>
      <Box component="div">
        <Typography variant="subtitle2" component="div" fontWeight="bold" mb={1}>
          Your Stable
        </Typography>
        <Box component="div" sx={{ mb: 1 }}>
          <CardsList cards={stable.actions} actions={stableActions} />
        </Box>
        <Box component="div">
          <CardsList cards={stable.unicorns} actions={stableActions} />
        </Box>
      </Box>
      <Divider sx={{ my: 1 }}></Divider>
      <Box component="div" sx={{ overflow: shortHeight ? null : 'auto' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="subtitle2" component="div" fontWeight="bold" sx={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1 }}>
            Your Hand
          </Typography>
          <IconButton
            onClick={() => {
              setOpen(!open)
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
        <Collapse in={open} timeout="auto">
          <Box component="div">
            <CardsList cards={hand} actions={handActions} />
          </Box>
        </Collapse>
      </Box>
      {currentFocusSelection !== undefined && (
        <CardFocusDialog
          open={currentFocusSelection !== undefined}
          onClose={() => {
            setCurrentFocusSelection(undefined)
          }}
          card={currentFocusSelection}
        />
      )}
    </>
  )
}
