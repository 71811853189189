import CloseIcon from '@mui/icons-material/Close'
import { Box, DialogContent, Divider, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import { type CardProps } from '../../models'

export interface CardDetailsDialogProps {
  open: boolean
  card: CardProps | undefined
  onCancel: () => void
}

const types: Record<string, string> = {
  baby_unicorn: 'Baby Unicorn',
  basic_unicorn: 'Basic Unicorn',
  magical_unicorn: 'Magical Unicorn',
  magic: 'Magic',
  instant: 'Instant',
  upgrade: 'Upgrade',
  downgrade: 'Downgrade'
}

export function CardDetailsDialog(props: CardDetailsDialogProps): JSX.Element {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { open, card, onCancel } = props

  const handleClose = (): void => {
    onCancel()
  }

  return (
    <>
      {card != null && (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
          <DialogContent>
            <Box sx={{ display: 'flex', gap: 4 }}>
              {!mobile && (
                <Box sx={{ width: '35%', flex: 'none' }}>
                  <img src={`/api/${card.image}`} alt={card.title} loading="lazy" width={'100%'} />
                </Box>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: 2 }}>
                <Box component="div" sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Box component="div" sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="h6">
                      {card.title}
                    </Typography>
                    <Typography variant="subtitle2" component="div">
                      Type: {types[card.type]} | Quantity: {card.quantity}
                    </Typography>
                  </Box>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Divider></Divider>

                <Box component="div" sx={{ flexGrow: 1 }}>
                  <Typography variant="body1" component="div">
                    {card.description}
                  </Typography>
                </Box>
                {mobile && (
                  <>
                    <Divider></Divider>
                    <img src={`/api/${card.image}`} alt={card.title} loading="lazy" width={'100%'} />
                  </>
                )}
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
