import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Button, Chip, Container, TextField } from '@mui/material'

import { Navbar } from '../components/Navbar'
import { getSessionID, setSessionID, SocketContext } from '../context/Socket'
import { type PlayerProps } from '../models'

function Home(): JSX.Element {
  const navigate = useNavigate()
  const socket = useContext(SocketContext)
  const ref: any = useRef()

  const [searchParams] = useSearchParams()
  const isAdmin = searchParams.get('type') === 'eusoufoda'

  const [players, setPlayers] = useState<any>([])

  const handleJoin = (): void => {
    const username = ref.current.value

    if (username === undefined) return

    socket.emit('add-player', { username })
  }

  const handleStart = (): void => {
    socket.emit('start-game')
  }

  useEffect(() => {
    socket.on('session', ({ sessionID, username }) => {
      socket.auth = { sessionID, username }

      setSessionID(sessionID)
    })

    socket.on('added-player', (playersList) => {
      if (Array.isArray(playersList)) setPlayers(playersList)
      else setPlayers([])
    })

    socket.on('game-start', () => {
      if (isAdmin) return navigate('/board?type=eusoufoda')
      navigate('/board')
    })

    socket.emit('startup')

    return () => {
      socket.off('session')
      socket.off('added-player')
      socket.off('game-start')
    }
  }, [])

  return (
    <>
      <Navbar />
      <Container component="main" maxWidth="sm">
        <Box sx={{ my: 2 }}>
          <Box component="form" autoComplete="off" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <TextField inputRef={ref} required fullWidth margin="dense" id="name" label="Name" variant="standard" />
            <Button variant="contained" onClick={handleJoin} color="secondary">
              {players.findIndex((player: PlayerProps) => player.id === getSessionID()) === -1 ? 'Join' : 'Rename'}
            </Button>
            {isAdmin && (
              <Button variant="contained" onClick={handleStart} color="secondary">
                Start
              </Button>
            )}
          </Box>
          <Box component="div" sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {players.map((player: any) => (
              <Chip key={player.id} label={player.name} variant={player.id === getSessionID() ? 'filled' : 'outlined'} color="secondary" />
            ))}
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default Home
