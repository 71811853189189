import { useState } from 'react'
import { ButtonBase, Menu, MenuItem, Tooltip, Typography } from '@mui/material'

interface ActionProps {
  text: string
  action: () => void
}
interface UnstableCardProps {
  title?: string
  tooltip?: string
  image: string | undefined
  actions?: ActionProps[]
}

export function UnstableCard({ title, tooltip, image, actions = [] }: UnstableCardProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (actions?.length === 0) return

    if (actions != null && actions.length === 1) {
      actions[0].action()
      return
    }

    setAnchorEl(event.currentTarget)
  }

  const handleClose = (onClose?: ActionProps['action']): void => {
    setAnchorEl(null)

    if (typeof onClose === 'function') onClose()
  }

  return (
    <>
      {typeof title === 'string' && (
        <Typography variant="subtitle2" component="div" textAlign="center" noWrap>
          {title}
        </Typography>
      )}
      <Tooltip title={tooltip} placement="top" arrow>
        <ButtonBase onClick={handleClick} sx={{ cursor: actions?.length === 0 ? 'auto' : null }}>
          {typeof image === 'string' && <img src={image} loading="lazy" style={{ width: '100%' }} />}
        </ButtonBase>
      </Tooltip>
      {actions != null && actions.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            handleClose()
          }}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {actions.map(({ text, action }) => (
            <MenuItem
              key={text}
              onClick={() => {
                handleClose(action)
              }}
            >
              {text}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}
