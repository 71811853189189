import { useContext } from 'react'
import { Grid } from '@mui/material'

import { GameStateContext } from '../../context/Game'
import { UnstableCard } from '../UnstableCard'

// import { getCardDetails } from '../../context/Game'
// import { UnstableCard } from '../UnstableCard'

interface ActionProps {
  text: string
  includeType?: string[]
  excludeType?: string[]
  action: (card: string) => void
}
interface CardsListProps {
  cards: string[]
  actions?: ActionProps[]
  showBackCard?: boolean
  backCardActions?: ActionProps[]
}

function CardsList({ cards, actions = [], showBackCard = false, backCardActions = [] }: CardsListProps): JSX.Element {
  const { handler } = useContext(GameStateContext)

  const parsedBackCardActions = backCardActions.map(({ text, action }) => ({
    text,
    action: () => {
      // action(card)
    }
  }))

  return (
    <Grid container columns={{ xs: 4, md: 5, lg: 7 }} spacing={1}>
      {showBackCard && (
        <Grid item xs={1}>
          <UnstableCard image={'/api/images/back.png'} actions={parsedBackCardActions}></UnstableCard>
        </Grid>
      )}
      {cards.map((card, i) => {
        const details = handler.getCardDetails(card)

        if (details === undefined) return <></>

        const parsedActions = actions
          .filter(({ includeType }) => includeType === undefined || includeType.includes(details.type))
          .filter(({ excludeType }) => excludeType === undefined || !excludeType.includes(details.type))
          .map(({ text, action }) => ({
            text,
            action: () => {
              action(card)
            }
          }))

        return (
          <Grid item xs={1} key={`${card}_${i}`}>
            <UnstableCard tooltip={`${details?.title} - ${details?.description}`} image={`/api/${details?.image}`} actions={parsedActions}></UnstableCard>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default CardsList
