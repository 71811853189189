import { useContext, useState } from 'react'
import { Box, Chip, Grid, Typography, useTheme } from '@mui/material'

import { GameStateContext } from '../../context/Game'
import { SocketContext } from '../../context/Socket'
import { type CardProps } from '../../models'
import { CardFocusDialog } from '../CardFocusDialog'
import { CardSelectionDialog } from '../CardSelectionDialog'
import { UnstableCard } from '../UnstableCard'

interface PileSelectionProps {
  source: string
  cards: string[]
}

export function DecksPiles(): JSX.Element {
  const theme = useTheme()

  const socket = useContext(SocketContext)
  const { state, handler } = useContext(GameStateContext)

  const [currentFocusSelection, setCurrentFocusSelection] = useState<CardProps | undefined>(undefined)
  const [currentPileSelection, setCurrentPileSelection] = useState<PileSelectionProps | undefined>(undefined)

  const { id } = handler.getPlayerInfo()
  const { nursery, discard, deck } = state.piles

  const nurseryTopCard = nursery[nursery.length - 1]
  const nurseryCardDetails = handler.getCardDetails(nurseryTopCard)
  const nurseryImage = nurseryCardDetails !== undefined ? `/api/${nurseryCardDetails.image}` : undefined

  const discardTopCard = discard[discard.length - 1]
  const discardCardDetails = handler.getCardDetails(discardTopCard)
  const discardImage = discardCardDetails !== undefined ? `/api/${discardCardDetails.image}` : undefined

  const nurseryPileAction = [
    { text: 'Take To Stable', action: () => socket.emit('move-pile', { card: nursery.pop(), source: 'nursery', target: `player_${id}_stable` }) },
    {
      text: 'Focus',
      action: () => {
        const cardDetails = handler.getCardDetails(nursery[nursery.length - 1])
        setCurrentFocusSelection(cardDetails)
      }
    }
  ]

  const deckPileAction = [
    { text: 'Draw Card', action: () => socket.emit('move-pile', { card: deck.pop(), source: 'deck', target: `player_${id}_hand` }) },
    { text: 'Shuffle', action: () => socket.emit('shuffle-deck-pile') },
    {
      text: 'Check All Cards',
      action: () => {
        setCurrentPileSelection({ source: 'deck', cards: [...deck].reverse() })
        socket.emit('check-deck-pile')
      }
    }
  ]

  const discardPileAction = [
    { text: 'Take To Hand', action: () => socket.emit('move-pile', { card: discard.pop(), source: 'discard', target: `player_${id}_hand` }) },
    { text: 'Take To Stable', excludeType: ['instant'], action: () => socket.emit('move-pile', { card: discard.pop(), source: 'discard', target: `player_${id}_stable` }) },
    { text: 'Shuffle Into Deck', action: () => socket.emit('shuffle-discard-pile') },
    {
      text: 'Check All Cards',
      action: () => {
        setCurrentPileSelection({ source: 'discard', cards: [...discard].reverse() })
        socket.emit('check-deck-pile')
      }
    },
    {
      text: 'Focus',
      action: () => {
        const cardDetails = handler.getCardDetails(discard[discard.length - 1])
        setCurrentFocusSelection(cardDetails)
      }
    }
  ]

  return (
    <>
      <Grid container columns={{ xs: 3, lg: 7 }} spacing={1}>
        <Grid item xs={3}>
          <Typography variant="subtitle2" component="div" textAlign="center" noWrap>
            {"Player's Turns Order:"}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2, maxWidth: '100%', flexWrap: 'wrap' }}>
            {handler.getPlayersOrder().map((player) => (
              <Chip key={player.id} label={player.name} variant={player.id === handler.getCurrentPlayer() ? 'filled' : 'outlined'} color="secondary" />
            ))}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <UnstableCard title={`Nursery: ${nursery.length} cards`} image={nurseryImage} actions={nurseryPileAction}></UnstableCard>
        </Grid>
        <Grid item xs={1}>
          <UnstableCard title={`Deck: ${deck.length} cards`} image="/api/images/back.png" actions={deckPileAction}></UnstableCard>
        </Grid>
        <Grid item xs={1}>
          <UnstableCard title={`Discard: ${discard.length} cards`} image={discardImage} actions={discardPileAction}></UnstableCard>
        </Grid>
      </Grid>
      {currentFocusSelection !== undefined && (
        <CardFocusDialog
          open={currentFocusSelection !== undefined}
          onClose={() => {
            setCurrentFocusSelection(undefined)
          }}
          card={currentFocusSelection}
        />
      )}
      {currentPileSelection !== undefined && (
        <CardSelectionDialog
          open={currentPileSelection !== undefined}
          onClose={() => {
            setCurrentPileSelection(undefined)
          }}
          source={currentPileSelection.source}
          cards={currentPileSelection.cards}
        />
      )}
    </>
  )
}
